<template>
  <div id="app">
   <router-view/>
  </div>
</template>


<script>
  export default {
    name: 'App',
    
  };
</script>
<style>
html,body,#app{
  height: 100%;
  min-width:1300px
}
</style>
