import axiosLogin from './apiLoginUrl'
import axiosPython from './apiPythonUrl'
import qs from 'qs'

var axios_yz=function (ret,callback,error_callback){
    if(ret['status']=='ok'||ret['code']==1){
        var result= ret['data'];
        if(callback){
          callback(result);
        }else{
          return result;
        }
      }else{
        if(error_callback){
          error_callback(ret);
        }else{
          console.log("错误代码返回");
          console.log(ret);
        }
      }
}

var request=function(url,method,params,callback,error_callback,type){
   if(type=="python"){
        if(method=="post"){
         if(url=="/user/login"){
            axiosPython.post(url,params)
            .then(response=>{
              axios_yz(response['data'],callback,error_callback);
            })
          }else if(url=="/macro/uploadt"){
            params.append("user_id",localStorage.getItem("userId"))
            axiosPython.post(url,params, {headers:{'Content-Type':'multipart/form-data'}})
            .then(response=>{
              axios_yz(response['data'],callback,error_callback);
            })
         }else{
            if(!isArray(params)){
              params.user_id=localStorage.getItem("userId")
             }
             axiosPython.post(url,params)
             .then(response=>{
               axios_yz(response['data'],callback,error_callback);
             })
          }
        }else if(method=="get"){
          if(params==null){
              let par={}
              params=par
          }
          params.userId=localStorage.getItem("userId")
          axiosPython.get(url,{'params':params})
          .then(response=>{
            axios_yz(response['data'],callback,error_callback);
          })
        }
      }
}

var isArray=function (obj){
  return Object.prototype.toString.apply(obj)=="[object Array]";
}

var getUrl=function(){
  
  return axiosPython.defaults.baseURL
}
export default{
    request,getUrl
}