import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from  "element-ui";
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false;
import Api from './assets/api.js'
import Util from './common/js/util.js'
import './common/css/common.css'


// router.beforeEach((to, from, next) => {
//   var path=to.path;
//   if (to.meta.title) {
//     document.title = to.meta.title
//   }
//   if(localStorage.getItem("userId")){
//     next();
//   }else{
//     if(path=="/index"||path=="/login"){
//       next();
//     }else{
//       router.push({name:'login'});
//     }
//   }
// })
Vue.use(ElementUI);
Vue.prototype.Api=Api
Vue.prototype.Util=Util
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
