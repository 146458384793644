var setStorage=function (key,data){
    // if($.isArray(data)||$.isPlainObject(data)){
    //   localStorage.setItem(key,JSON.stringify(data))
    // }else{
      localStorage.setItem(key,data)
    // }
  }
var getStorage=function (key){
    // if(type=="Object"||type=="Array"){
    //   if(localStorage.getItem(key)){
    //     return JSON.parse(localStorage.getItem(key))
    //   }else{
    //     if(type=="Array"){return []}
    //     else if(type=="Object"){return null}
    //   }
    // }else{
      return localStorage.getItem(key)
    // }
  }
var removeStorage=function (key){
   localStorage.removeItem(key)
  }

  var mainHeight=function(){
    var clientHeight = document.documentElement.clientHeight;
    var tableh=(clientHeight-302)+"px"
    let tableHeight= {'min-height':tableh}
    return tableHeight
  }
  
export default{
    getStorage,removeStorage,setStorage,mainHeight
}