import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    redirect:'/'
  },
  {
    path: "/login",
    name: "login",
    component:  () =>
    import( "@/views/login.vue"),
    meta: {title:'登录'}
  },
  {
    path: '/',
    name:'index',
    component:  () =>
    import( "@/views/index.vue"),
    redirect: '/home',
    meta: {title:'首页'},
    children:[
      {
        path:'/home',
        name:'home',
        component:() =>import( "@/views/home.vue"),
        meta:{title:'首页'}
      },
      {
        path:'/about',
        name:'about',
        component:() =>import( "@/views/about/about.vue"),
        meta:{title:'关于我们'},
        children:[
          {
            path:'/aboutCompany',
            name:'aboutCompany',
            component:() =>import( "@/views/about/aboutCompany.vue"),
            meta:{title:'公司介绍'},
          },
          {
            path:'/aboutNews',
            name:'aboutNews',
            component:() =>import( "@/views/about/aboutNews.vue"),
            meta:{title:'新闻动态'},
          },
          {
            path:'/aboutQualify',
            name:'aboutQualify',
            component:() =>import( "@/views/about/aboutQualify.vue"),
            meta:{title:'产品资质'},
          },
          {
            path:'/aboutMember',
            name:'aboutMember',
            component:() =>import( "@/views/about/aboutMember.vue"),
            meta:{title:'招贤纳士'},
          }  
        ]
      },
      {
        path:'/aboutNewsInfo',
        name:'aboutNewsInfo',
        component:() =>import( "@/views/about/aboutNewsInfo.vue"),
        meta:{title:'新闻动态'},
      },
      {
        path:'/productInfo',
        name:'productInfo',
        component:() =>import( "@/views/product/productsInfo.vue"),
        meta:{title:'产品中心'},
      },
      {
        path:'/product',
        name:'product',
        component:() =>import( "@/views/product/product.vue"),
        meta:{title:'产品中心'},
        children:[
          {
            path:'/productResearch/:type',
            name:'productResearch',
            component:() =>import( "@/views/product/productResearch.vue"),
            meta:{title:'产品中心'},
          },
          {
            path:'/productByCondition/:type',
            name:'productByCondition',
            component:() =>import( "@/views/product/productByCondition.vue"),
            meta:{title:'产品中心'},
          },
        ]
      },
      {
        path:'/example',
        name:'example',
        component:() =>import( "@/views/example/example.vue"),
        meta:{title:'成功案例'},
        children:[
          {
            path:'/exampleSay',
            name:'exampleSay',
            component:() =>import( "@/views/example/exampleSay.vue"),
            meta:{title:'客户感言'},
          },
          {
            path:'/exampleMember',
            name:'exampleMember',
            component:() =>import( "@/views/example/exampleMember.vue"),
            meta:{title:'合作客户'},
          },
        ]
      },
      {
        path:'/solution',
        name:'solution',
        component:() =>import( "@/views/solution/solution.vue"),
        meta:{title:'解决方案'},
        children:[
          {
            path:'/solutionView/:type',
            name:'solutionView',
            component:() =>import( "@/views/solution/solutionView.vue"),
            meta:{title:'解决方案'},
          },
          // {
          //   path:'/solutionView/ehs',
          //   name:'solutionViewEhs',
          //   component:() =>import( "@/views/solution/ehs.vue"),
          //   meta:{title:'解决方案'},
          // }
        ]
      },
      {
        path:'/test',
        name:'test',
        component:() =>import( "@/views/test.vue"),
        meta:{title:'合作客户'}
      },
      {
        path:'/aboutContact',
        name:'aboutContact',
        component:() =>import( "@/views/aboutContact.vue"),
        meta:{title:'联系我们'},
      }
    ]
  }
 
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
